.Live {
  background-color: #000;
  min-height: 58vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.player-container {
  padding-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  width: 640px;
  height: 360px;
  background-color: #333;
  display: flex;
  align-items: center;
  position: relative;
}

.placeholder-player {
  width: 640px;
  height: 360px;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: relative;
}

.live-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.live-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.live-circle.visible {
  opacity: 1;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #444;
}
