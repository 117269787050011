.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25px;
}

.silhouette {
  max-height: 420px;
  max-width: 420px;
  object-fit: contain;
  margin-top: 78px;
  position: relative;
  animation: fadeIn 6s ease-out, halo 6s infinite alternate;
  opacity: 0;
  animation-fill-mode: forwards;
}

.silhouette::after {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.3);
  z-index: -1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes halo {
  0% {
    box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 50px 25px rgba(255, 255, 255, 0.5);
  }
}
