.content-container {
  padding-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tracks-list {
  width: 45%;
  flex-direction: column;
  align-items: center;
  align-text: center;
}
