.App {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Jura", sans-serif;
}

.logo-space {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-height: 75px;
  max-width: 75px;
  object-fit: contain;
  margin-top: 20px;
}

.logo-text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.logo-text.left {
  left: 1380px;
}

.logo-text.right {
  right: 20px;
}

.App-header {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

h1 {
  font-size: 3rem;
  margin: 0;
}

.nav-link {
  text-decoration: none;
  color: white;
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
}
